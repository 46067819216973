import { Action, Module, Mutation, MutationAction, VuexModule, getModule } from "vuex-module-decorators";
import NewTransferDetailState from "../../models/NewTransferDetailState";
import Modules from "@/store/enums/Modules";
import store from "@/store";
import { FormTypes } from "@/core/enums/form-types";
import OptionSelect from "@/models/shared/OptionSelect";
import NewTransferDetails from "@/models/weewhouse/NewTransferDetails";
import { MutationsForm } from "@/store/commons/MutationsForm";
import { ActionsForm } from "@/store/commons/ActionsForm";
import { realoadTable } from "@/store/functions/GenericTable";
import WarehouseTransfersService from "@/core/services/werehouse/WarehouseTransfersService";
import NewTransferDetailService from "@/core/services/werehouse/interfaces/NewTransferDetailService";
import ProductSearchComboService from "@/core/services/products/interfaces/productSearchComboService";
import ProductsService from "@/core/services/products/ProductsService";
import ProductOption from "@/models/products/productOption";

const defaultForm = {
    id: ''
    , productId: ''
    , unitOfMeasurementId: ''
    , quantity: 0
    , price: 0
    , total: 0
} as NewTransferDetails;

@Module({ dynamic: true, store, namespaced: true, name: Modules.NewTransferDetail })
class NewTransferDetailModule extends VuexModule implements NewTransferDetailState {
    transferDetail = defaultForm;
    formType = FormTypes.Create;
    selects = {
        unitOfMeasurementOptions: [] as OptionSelect[]
        , colorOptions: [] as OptionSelect[]
        , productOptions: [] as ProductOption[]
    };
    loading = false;
    idSelected = ''; 

    get getTranferDetailInfo(): NewTransferDetails {
        return this.transferDetail;
    }

    @Mutation
     [MutationsForm.SET_DATA_FORM](value: NewTransferDetails | undefined) {
        if (value) {
            this.transferDetail = value;
            this.idSelected = value.id;
        }
    }
    @Mutation
    [MutationsForm.RESET_ID_SELECT](){
        this.idSelected = '';
    }
    @Mutation
    [MutationsForm.SET_LOADINGS](value: boolean){
        this.loading = value;
    }
    @Mutation
    [MutationsForm.SET_FORM_TYPE](newFormType: FormTypes){
        this.formType = newFormType;
    }
    
    @Mutation
    [MutationsForm.RESET_VALUES_FORM](){
        this.transferDetail = defaultForm;
        this.idSelected = '';
    }
    @Action({ commit: MutationsForm.SET_DATA_FORM })
    async [ActionsForm.SEARCH_DATA_FORM](id: string){
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        return (await service.getInfoDetail(id)
            .finally( () => this.context.commit(MutationsForm.SET_LOADINGS, false))).data ?? []
    }
    @Action
    async [ActionsForm.SAVE_FORM](form: NewTransferDetails) {
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        const transferId = this.context.rootState[Modules.TableTransfersModule]?.rowSelected?.id ?? '';
        return service.saveDetail(transferId, form)
            .then((resp) => {
                let isSuccess = false;
                if(resp.data){
                    realoadTable(this.context, Modules.TableTransferDetailsModule)
                    this.context.commit(MutationsForm.RESET_VALUES_FORM)
                    isSuccess = true;
                } 
                return isSuccess;
            })
            .finally(() => {
                this.context.commit(MutationsForm.SET_LOADINGS, false)
            })
    }
    /**
     * AUN NO SE IMPLEMENTA EL UPDATE PARA LOS DETALLES
     */
    /*@Action
    async [ActionsForm.UPDATE_FORM](newData: NewTransferDetails){
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        return service.updateDetail(this.idSelected, newData)
            .then((resp) => {
                let isSuccess = false;
                if(resp.data){
                    realoadTable(this.context, Modules.TableTransferDetailsModule)
                    this.context.commit(MutationsForm.RESET_VALUES_FORM)
                    isSuccess = true;
                } 
                return isSuccess;
            })
            .finally( () => {
                this.context.commit(MutationsForm.SET_LOADINGS, false)
            });
    }*/

    @MutationAction
     async getComboProducts(filterName: string) {
         const comboOptions = await productService.searchProducts(filterName);
         const selects = { ...this.selects }
        if(filterName.trim() == '') return  { selects: { ...selects } }
        
        return { selects: { ...selects, productOptions: comboOptions.data ?? [] } }
    }
    @MutationAction
    async setHiddenInformationProduct(productId: string) {
        const productSelected = this.selects.productOptions.find(p => p.id == productId);
        let unitOfMeasurementOptions = this.selects.unitOfMeasurementOptions;
        let colorOptions = this.selects.colorOptions;
        const transferDetail = this.transferDetail;
        if (productSelected) {
            unitOfMeasurementOptions = [
                {
                    id: productSelected?.unitOfMeasurementId ?? ''
                    , name: productSelected?.unitOfMeasurementName ?? ''
                }
            ]
            colorOptions = [
                {
                    id: productSelected?.colorId ?? ''
                    , name: productSelected?.colorName ?? ''
                }
            ]
            transferDetail.price = productSelected?.price ?? 0;
            transferDetail.quantity = 1;//por defecto uno cuando se cambie
            transferDetail.unitOfMeasurementId = productSelected?.unitOfMeasurementId ?? '';
            transferDetail.total = 0;
            transferDetail.productId = productId;
        }
        const selects = { ...this.selects };
        
        return { selects: { ...selects, unitOfMeasurementOptions: unitOfMeasurementOptions, colorOptions: colorOptions }, transferDetail: { ...transferDetail}}
    }
}
const service: NewTransferDetailService = new WarehouseTransfersService();
const productService: ProductSearchComboService = new ProductsService();

export default getModule(NewTransferDetailModule);
