
import { Form } from "vee-validate";
import { defineComponent, computed, watch, ref, onMounted } from "vue";
import * as Validations from "yup";
import InputNumber from "@/components/forms/InputNumber.vue"
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import Select from "@/components/forms/Select.vue"
import InputText from "@/components/forms/InputText.vue"
import moduleForm from "@/store/modules/werehouse/modules/transfer-details-form"
import { hideModalById } from "@/core/helpers/dom";
import { FormTypes } from "@/core/enums/form-types";
import NewTransferDetails from "@/models/weewhouse/NewTransferDetails";
import { InputTextMaskTypes } from "@/core/enums/input-text-mask-types";
import _ from "lodash";

export default defineComponent({
    components: {
        Form
        , InputNumber
        , SelectFilterRemote
        , Select
        , InputText
    },
    setup() {
        const formTransfer = ref();

        const validationSchema = computed(() => Validations.object().shape({
            productId: Validations.string().default('').required()
            , unitOfMeasurementId: Validations.string().default('').required()
            , quantity: Validations.number().default(1).required()
            , price: Validations.string().default('')
            , total: Validations.string().default('').required()
        }));

        const saveTransferDetail = (form: NewTransferDetails,  { resetForm }): void => {
            //save transfer
            /*if (formType.value == FormTypes.Update) { 
                moduleForm.UPDATE_FORM(form)
                    .then((isSuccess) => { 
                        if (isSuccess) { 
                            hideModalById('#new-transfer');
                            resetForm();
                        } 
                    })
            } else {*/
                moduleForm.SAVE_FORM(form)
                    .then((isSuccess) => { 
                        if (isSuccess) {
                            hideModalById('#new-transfer-detail');
                            resetForm();
                        }
                    });
            //}
        }

        const unitOfMeasurementOptions = computed(() => moduleForm.selects.unitOfMeasurementOptions);
        const productOptions = computed(() => moduleForm.selects.productOptions);
        const formType = computed(() => moduleForm.formType);

        const searchProducts = _.debounce((filterText: string) => {
            moduleForm.getComboProducts(filterText);
        }, 550, { leading: false, trailing: true });

        const changeProduct = (productId: string) => {
            moduleForm.setHiddenInformationProduct(productId);
        }
        const reloadTotal = (newQuantity: number) => { 
            const actualPrice = moduleForm.getTranferDetailInfo.price;
            //const actualQuantity = moduleForm.getTranferDetailInfo.quantity;

            let newTotal = newQuantity * actualPrice;
            formTransfer.value.setFieldValue('total', newTotal);
        }

        watch(() => moduleForm.getTranferDetailInfo, (currentValue) => {
            if (currentValue ) {
                formTransfer.value.setValues({
                    ...(currentValue as NewTransferDetails)
                });
                reloadTotal(currentValue.quantity);
                //en la asignacion buscamos la información del producto
            } else {
                formTransfer.value.resetForm();
            }
        })

        onMounted(() => { 
            //por mientras hacemos estas llamadas
        })

        return {
            validationSchema
            , saveTransferDetail
            , formTransfer
            , formType
            , FormTypes
            , maskMoney: InputTextMaskTypes.Money
            , productOptions
            , searchProducts
            , unitOfMeasurementOptions
            , changeProduct
            , reloadTotal
        }
    }
})
