
import { defineComponent } from "vue"
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import NewTransferDetailForm from "./NewTransferDetailForm.vue"
import { hideModalById } from "@/core/helpers/dom"

export default defineComponent({
    components: {
        DynamicContentModal
        , NewTransferDetailForm
    }
    , setup() {
        const closeModal = () => {
            hideModalById("#new-transfer-detail");
        };
        return {
            closeModal
        }
    }
})
