export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "BUTTONS": {
          "SUBMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
          "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])}
        },
        "LABELS": {
          "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producto"])},
          "UNIT_OF_MEASUREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidad de Medida"])},
          "QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
          "PRICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
          "TOTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
        }
      }
    }
  })
}
