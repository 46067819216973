import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row g-4 mb-8" }
const _hoisted_2 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_3 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_4 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_5 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_6 = { class: "col-md-12 col-sm-12 fv-row" }
const _hoisted_7 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_8 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_9 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_10 = { class: "col-md-6 col-sm-12 fv-row" }
const _hoisted_11 = { class: "fs-6 fw-bold mb-2" }
const _hoisted_12 = {
  key: 0,
  class: "d-flex flex-stack pt-15"
}
const _hoisted_13 = {
  type: "submit",
  class: "btn btn-lg btn-primary me-3"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectFilterRemote = _resolveComponent("SelectFilterRemote")!
  const _component_Select = _resolveComponent("Select")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_Form, {
      as: "el-form",
      "validation-schema": _ctx.validationSchema,
      onSubmit: _ctx.saveTransferDetail,
      class: "mx-auto w-100 pt-15 pb-10",
      ref: "formTransfer"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("LABELS.PRODUCT")), 1),
            _createVNode(_component_SelectFilterRemote, {
              name: "productId",
              placeholder: 'Seleciona un producto',
              options: _ctx.productOptions,
              onSearch: _ctx.searchProducts,
              onChange: _ctx.changeProduct,
              disabled: _ctx.formType != _ctx.FormTypes.Create
            }, null, 8, ["options", "onSearch", "onChange", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("LABELS.UNIT_OF_MEASUREMENT")), 1),
            _createVNode(_component_Select, {
              name: "unitOfMeasurementId",
              placeholder: '',
              options: _ctx.unitOfMeasurementOptions,
              disabled: true
            }, null, 8, ["options"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("LABELS.QUANTITY")), 1),
            _createVNode(_component_InputNumber, {
              name: 'quantity',
              placeholder: 'Cantidad',
              min: 1,
              disabled: _ctx.formType == _ctx.FormTypes.Read,
              onChange: _ctx.reloadTotal
            }, null, 8, ["disabled", "onChange"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("LABELS.PRICE")), 1),
            _createVNode(_component_InputText, {
              name: 'price',
              mask: _ctx.maskMoney,
              placeholder: 'Precio del producto',
              disabled: true
            }, null, 8, ["mask"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("LABELS.TOTAL")), 1),
            _createVNode(_component_InputText, {
              name: 'total',
              mask: _ctx.maskMoney,
              placeholder: 'Total',
              disabled: true
            }, null, 8, ["mask"])
          ])
        ]),
        (_ctx.formType != _ctx.FormTypes.Read)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("button", _hoisted_13, [
                (_ctx.formType == _ctx.FormTypes.Update)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t("BUTTONS.EDIT")), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t("BUTTONS.SUBMIT")), 1))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["validation-schema", "onSubmit"])
  ]))
}