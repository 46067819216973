
import { defineComponent, onMounted, computed, reactive, ref } from "vue";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import moduleTableTransfer from "@/store/modules/werehouse/modules/transfers-table"
import moduleTable from "@/store/modules/werehouse/modules/transfer-details-table"

//import moduleForm from "@/store/modules/werehouse/modules/transfers-form"
import ModalNewTransferDetail from "../components/ModalNewTransferDetail.vue"
import CrudActionsV2 from "@/components/c-module-master/CrudActionsV2.vue";
import { FormTypes } from "@/core/enums/form-types";
import { translate } from "@/core/plugins/i18n";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { TransferStatus } from "@/core/enums/transfer-status" 
import moduleForm from "@/store/modules/werehouse/modules/transfer-details-form"
import moduleTransfers from "@/store/modules/werehouse/modules/transfers-table"
import { useRoute, useRouter } from "vue-router";
import SwalMessageService from "@/core/services/SwalMessageService";
import { SwalIconType } from "@/core/enums/swal-icon-type";
import  PDFViewer from "@/views/cashregister/components/PDFViewer.vue";
import ApiService from "@/core/services/ApiService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';

export default defineComponent({
    components: {
        ModuleMaster
        , Datatable
        , Datarow
        , CrudActionsV2
        , ModalNewTransferDetail
        , PDFViewer
    },
    setup() { 
        const messageService = new SwalMessageService();
        const openModal = ref<HTMLButtonElement>();
        const router = useRouter();
        const route = useRoute();
        const folioTransfer = ref('');
        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id", Type: "text", VisibleInGrid: false },
            { PropName: "productName", HeadLabel: "Producto", Type: "text", VisibleInGrid: true },
            { PropName: "unitOfMeasurementName", HeadLabel: "Unidad de medida", Type: "text", VisibleInGrid: true },
            { PropName: "quantity", HeadLabel: "Cantidad", Type: "text", VisibleInGrid: true },
            { PropName: "price", HeadLabel: "Precio", Type: "moneda", VisibleInGrid: true },
            { PropName: "total", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true }
        ]);
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar Detalle",
            titleNewCrudAction: "Nuevo Detalle",
        });

        const openPDF = ref();
        let pdfMovement = ref("");

        //computed
        const isInCapture = computed(() => moduleTableTransfer.isInCapture);
        const totalRecords = computed(() => moduleTable.totalRecords);
        const sizePage = computed(() => moduleTable.pagination.pageSize);
        const renderRows = computed(() => formatToDatatable(moduleTable.getRecordsTable, configTable));
        
        const handleChangePage = (page: number) => {
            moduleTable.CHANGE_PAGE({ currentPage: page });
        }
        const handleCrudAction = async (action: FormTypes, id: string) => { 
            if (typeof action === "string") {
                action = action == "N" ? FormTypes.Create
                    : (action == "M" ? FormTypes.Update
                        : action == "V" ? FormTypes.Read : FormTypes.Create);
            }

            moduleForm.SET_FORM_TYPE(action);
            if (action != FormTypes.Create) {
                moduleForm.SEARCH_DATA_FORM(id);
            } else {
                moduleForm.RESET_VALUES_FORM();
                moduleForm.RESET_ID_SELECT();
            }
            openModal.value?.click();
        }

        onMounted(() => {
            const transferId = route.query.transferId;
            const folio = moduleTransfers.getSelectedFolio;
            //identificamos si existe información del padres mediante el folio
            if (transferId && transferId != '' && !folio && folio == '') {
                //si no se tiene el row seleccionado lo buscamos
                moduleTransfers.searchInfoTransferSelected(transferId as string).then((isCorrect) => { 
                    if (isCorrect) {
                        initialComponent(moduleTransfers.getSelectedFolio)
                    } else { 
                        router.back()
                    }
                });
            } else {
                initialComponent(folio);
            }
            
        });
        const initialComponent = (foliotransfer) => { 
            folioTransfer.value = foliotransfer;
            moduleTable.SEARCH_TABLE_DATA();
            setCurrentPageBreadcrumbs(translate('BREADCRUMB.TRANSFERS_DETAILS'), [
                translate('BREADCRUMB.MODULES')
                , translate('BREADCRUMB.WAREHOUSE')
                , { name: translate('BREADCRUMB.TRANSFER', { folio: foliotransfer }), to: 'warehouse/transfers' }]);
        }

        const changeToFinalCapture = () => { 
             messageService.confirm(SwalIconType.Question, `¿Esta seguro de la acción de Finalizar la captura del traspaso <br>${folioTransfer.value}?`)
                .then(isConfirm => {
                    if (isConfirm) {
                        moduleTransfers.CHANGE_STATUS(TransferStatus.EndCapture)
                            .then(IsSuccess => {
                                if (IsSuccess) {
                                    messageService.success(`Se ha Finalizado la captura del traspaso ${folioTransfer.value}`)
                                }
                            })
                    }
                })
        }

        const print = () => { 
            ApiService.get(`Transfers/GetReceipt/${route.query.transferId}`)
            .then( resp => {
                if(resp.status == 200 && openPDF.value) {
                    pdfMovement.value = `data:application/pdf;base64,${resp.data}`;
                    openPDF.value.click();
                }
            }).catch(error => {
                getMessageError(error, messageService);
            });
        }

        return {
            headers,
            openPDF,
            pdfMovement

            , totalRecords
            , sizePage
            , renderRows
            , labels
            , openModal
            , handleChangePage
            , handleCrudAction
            , changeToFinalCapture
            , TransferStatus
            , isInCapture
            , print
        }
    }
})
